function get(client, slug, params = {}) {
  let url = `/butter_posts/${slug}?preview=${params.preview}`
  return client.$get(url,
    { timeout: 2000 }
  )
  .then(response => response)
  .catch(error => {
    throw error
  })
}

export default {
  get,
}
