import API from '../api/api'

export const state = () => ({
  data: {},
  loading: true,
  error: null,
  status:null
})

export const mutations = {
  set (state, data) {
    state.data = data
    state.loading = false
    state.error = data.error
  }
}

export const actions = {
  async getProduct ({ commit }, { productSlug, options, slugOverride }) {
    let { type, unique_id, locale, recache } = options
    let butterSlug = slugOverride ? slugOverride : productSlug
    return Promise.all([
      API.products.getProduct(this.$axios, productSlug),
      API.butterPages.get(this.$axios, butterSlug, { type, unique_id })
    ]).then(function(results) {
      let [ apiResult, butterResult ] = results
      let data = {
        ...apiResult.product,
        ...butterResult.fields,
        butterPageType: butterResult.page_type,
        butterExperimentId: butterResult.experiment_id,
        butterVariantId: butterResult.variant_id
      }
      commit("set", data)
    }).catch(function(error) {
      console.log("error", error )
      commit("set", { error: error.message, status: error.response.status })
      throw error.response.status
    })
  },

}
