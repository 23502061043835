import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class CompanyCorrespondence extends BaseModel {
  static entity = "CompanyCorrespondence";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      state: this.attr(""),
      created_at: this.attr(null),
      read_at: this.attr(null),
      type: this.attr(""),
      size: this.attr(null),
      read: this.attr(null),
      company_id: this.attr(null),
    };
  }


  static async fetchCompanyCorrespondence(id, loadingAll) {
    let { data } = await this.axios.get(`/companies/${id}/registered_agent_documents`)
    for(var i = 0; i < data.length; i++)
      data[i].company_id = parseInt(id)
    
    Requests.markAsLoaded('CompanyCorrespondence') 
    this.insertOrUpdate({ data });
  }

}
