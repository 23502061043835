import { Model } from "@vuex-orm/core";

export default class BaseModel extends Model {
  static axios = null;

  static setAxios(axios) {
    this.axios = axios;
  }

  static async loadActive(params) {
    let data = await this.findBy(params);
    await this.create({ data });
    return this.query().first();
  }

  // by default findBy ID.
  static findByUrl({ id }) {
    return `/${this.entity}/${id}`;
  }

  static async findBy(params) {
    let response = await this.axios.get(this.findByUrl(params));
    return response.data;
  }

  // return just first for now, once we add in all vue we'll need flag here.
  static active() {
    return this.query().first();
  }

  static $patch(id, attributes) {
    return this.axios.patch(this.findByUrl({ id }), attributes);
  }

  static async $create(attributes) {
    return await this.axios.post(`/${this.entity}`, attributes);
  }

  static async fetchAll() {
    let { data } = await this.axios.get(`/${this.entity}`);
    this.create({ data });
    let all = await super.all();
    return all;
  }

  apiUpdate(attributes) {
    return this.constructor.$patch(this.id, attributes)
  }
}
