import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";
export default class UserSubscriptions extends BaseModel {
  static entity = "UserSubscriptions";

  static fields() {
    return {
      id: this.attr(null),
      plan_id: this.attr(null),
      billing_info_id: this.attr(null),
      state: this.attr(""),
      activated_at: this.attr(""),
      canceled_at: this.attr(""),
      trial_started_at: this.attr(""),
      trial_ends_at: this.attr(""),
      current_period_ends_at: this.attr(""),
      sub_type: this.attr(""),
      company_id: this.attr(null),
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/subscriptions`);
    Requests.markAsLoaded('UserSubscriptions')
    data.forEach(subscription => {
      this.insert({ data: subscription });
    });
    
  }

  static async startSubscription(newPlan, companyId, billingInfoId) {
     await this.axios.post(`/subscriptions`, {
      plan_id: newPlan,
      company_id: companyId ? companyId : null,
      billing_info_id: billingInfoId
    });
    Requests.markAsNotLoaded('UserSubscriptions')
    this.fetchAll();
  }

  static async updatePlan(subscriptionId, newPlan) {
    let { data } = await this.axios.patch(`/subscriptions/${subscriptionId}`, {
      plan_id: newPlan
    });
    Requests.markAsNotLoaded('UserSubscriptions')
    this.fetchAll();
  }

  static async updateCard(id, newCard) {
    let { data } = await this.axios.patch(`/subscriptions/${id}`, {
      billing_info_id: newCard
    });
    Requests.markAsNotLoaded('UserSubscriptions')
    this.fetchAll();
  }

  static async cancel(id) {
    let { data } = await this.axios.delete(`/subscriptions/${id}`);
    this.fetchAll();
  }

}
