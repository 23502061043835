async function getProduct(client, productName) {
  try {
    const url = `/products/${productName}`

    const response = await client.$get(url)
    return parseGetProductResponse(response.product)
  } catch (error) {
    throw error
  }
}

async function getAllProducts(client) {
  try {
    const url = `/products`;

    const response = await client.$get(url);

    return response;
  } catch (error) {
    throw error;
  }
}

async function getAllProductsByType(client, type) {
  try {
    const url = `/products/${type}`;

    const response = await client.$get(url);

    return response;
  } catch (error) {
    throw error;
  }
}

const parseGetProductResponse = object => {

  return {
    product: {
      ...object,
      productName: object.product_name,
      productDescription: object.product_description,
      productVersionSlug: object.product_version_slug,
      preview: object.preview,
      breadcrumbs: object.breadcrumbs,
      localeId: object.locale_id,
      slug: object.slug,
      categorySlug: object.category_slug
    }
  }
}

export default {
  getProduct: getProduct,
  getAllProducts:getAllProducts,
  getAllProductsByType: getAllProductsByType,
};