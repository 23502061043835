export default {
  get (client, params) {
    let baseUri = 'search'
    if (params.query || params.page || params.type)
      baseUri += '?'
    if (params.query)
      baseUri += `query=${params.query}&`
    if (params.page)
      baseUri += `page=${params.page}&`
    if (params.type)
      baseUri += `type=${params.type}&`
      
    return client.$get(baseUri, {})
  }
}