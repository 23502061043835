import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";
import API from "~/api/api";


export default class Addons extends BaseModel {
  static entity = "Addons";

  static fields() {
    return {
      id: this.attr(null),
      category: this.attr(""),
      category_slug: this.attr(""),
      product: this.attr(""),
      product_slug: this.attr(""),
      version_slug: this.attr(""),
      description: this.attr(""),
      duration: this.attr(""),
      price_cents: this.attr(null),
    };
  }

  static async fetchAll() {
    let  addons  = await this.axios.get(`/products?order_type=BusinessService`);
    Requests.markAsLoaded('Addons')
    if(addons.data)
    addons.data.forEach(product => {
      this.insert({ data: product });
    }); 
    
  }


}
