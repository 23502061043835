import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class UserOrdersProcessing extends BaseModel {
  static entity = "UserOrdersProcessing";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(null),
      created_at: this.attr(null),
      version_slug: this.attr(null),
      is_v2: this.attr(null),
      name: this.attr(null),
      status: this.attr(null),
      status: this.attr(null),
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/orders`, {
      params: {
        processing: true,
      }
      });
    Requests.markAsLoaded('OrdersProcessing')
    data.forEach(order => {
      this.insert({ data: order });
    });
    
  }

}
