import { each } from 'lodash'
import queryString from 'query-string'

/**
 * API docs: https://legalnature.docs.apiary.io/#reference/user-information/profile/view-a-profile-detail
 */

export const parseUserResponse = function (object) {
  return {
    'id': object.id,
    'firstName': object.first_name,
    'lastName': object.last_name,
    'email': object.email,
    'phone': object.phone,
    'planId': object.plan_id,
    'isPro': object.is_pro,
    'companies': object.companies,
    'avatarUrl': object.avatar_url,
    'bingConversion': object.bing_conversion,
    'subscriptionAccess': object.subscription_access,
    'unfinishedOrder': parseUnfinishedOrder(object.unfinished_order),
    'facebookConversion': object.facebook_conversion,
    'localeId': object.locale_id,
    'checkoutTest': object.checkout_test,
    'documentsInProgress': object.documents_in_progress,
    'businessFormationIncompleteItems': object.business_formation_incomplete_items,
    'businessFormationCompaniesBeingFormed': object.business_formation_companies_being_formed,
    'registered': object.registered,
    'formAbandonTest': object.form_abandon_test,
    'formTest': object.form_test,
    'defaultState': object.default_state
  }
}

export const parseUnfinishedOrder = function (object) {
  return object ? {
    'id': object.id,
    'name': object.name,
    'link': object.link,
    'accountOrder': object.account_order
  } : null
}

export const parseCardResponse = function (object) {
  return {
    'id': object.id,
    'last4': object.card_last_4,
    'creditCardNumber': object.creditCardNumber,
    'cardName': object.card_name,
    'expirationDate': object.card_expires_in,
    'cardType': object.card_type,
    'isPrimary': object.is_primary
  }
}

export const setFSUser = ({ displayName, email, uid }) => {
  if (window && window.FS) {
    window.FS.setUserVars({
      displayName,
      email,
      uid
    })
  }
}

export const setGAUser = (uid, email) => {
  if (window && window.$nuxt && window.$nuxt.$gtag) {
    if(email){
      window.$nuxt.$gtag.set({
        'user_id': uid,
        'user_data': {
          'email': email
        }
      })
    }
    else {
      window.$nuxt.$gtag.set({
        'user_id': uid
      })
    }
  }
}

async function getUser(client, params) {
  try {
    let url = '/profile'

    if (params && Object.keys(params).length > 0) {
      url += '?'+ queryString.stringify(params)
    }
    const response = await client.$get(url)
    const responseUser = parseUserResponse(response)
    setFSUser({
      displayName: responseUser.firstName + ' ' + responseUser.lastName,
      email: responseUser.email,
      uid: responseUser.id
    })
    setGAUser(responseUser.id, responseUser.email)
    return responseUser
  } catch (error) {
    throw error
  }
}

export default {
  getUser: getUser
}
