import BaseModel from "./BaseModel";

export default class Contact extends BaseModel {
  static entity = "contact";

  static fields() {
    return {
      from: this.attr(""),
      subject: this.attr(""),
      body: this.attr(""),
      name: this.attr(""),
      phone_number: this.attr(""),
    };
  }

  static createWithApi(contact) {
    return this.$create({ contact });
  }
}
