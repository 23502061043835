import products from "./products";
import user from "./user";
import butterPages from "./butterPages";
import butterCategories from "./butterCategories";
import butterPosts from "./butterPosts";
import categories from "./categories";
import documentBundle from "./documentBundle";
import newsletterSubscriber from "./newsletterSubscriber";
import estatePlanningOrders from "./estatePlanningOrders";
import businessForms from "./businessForms";
import addons from "./addons";
import search from "./search";
import allDocs from "./allDocs";
import stateInfo from './stateInfo'


export default {
  products,
  user,
  butterPages,
  butterCategories,
  butterPosts,
  categories,
  documentBundle,
  newsletterSubscriber,
  estatePlanningOrders,
  businessForms,
  addons,
  search,
  allDocs,
  stateInfo
};
