import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class CompanyAddons extends BaseModel {
  static entity = "CompanyAddons";

  static fields() {
    return {
      order_type: this.attr(null),
      title: this.attr(""),
      description: this.attr(null),
      default_price: this.attr(false),
      progress: this.attr(null),
      status: this.attr(null),
    };
  }


  static async fetchAll(id) {
    let { data } = await this.axios.get(`/addons?company_id=${id}`);
    Requests.markAsLoaded('CompanyAddons') 
    this.create({ data });
    
  }


}
