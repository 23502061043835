import Vue from 'vue'
import vMediaQuery from 'v-media-query'
if (process.client) {
  Vue.use(vMediaQuery, {
    variables: {
      xs: '480px',
      sm: '768px',
      md: '992px',
      lg: '1200px',
      xsMax: '767px',
      smMax: '991px',
      mdMax: '1199px'
    }
  })
}