import BaseModel from "./BaseModel";

export default class BankOfAmericaLead extends BaseModel {
  static entity = "bank_of_america_leads";

  static fields() {
    return {
      id: this.attr(null),
    };
  }

  static createOrRemoveLead(params) {
    return this.$create({
      bank_of_america_lead: params,
    });
  }
}
