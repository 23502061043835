import BaseOrder from "./BaseOrder";
import API from "~/api/api";
import Requests from "./Requests";

export default class Product extends BaseOrder {
  static entity = "products";

  static fields() {
    return {
      id: this.attr(null),
      category: this.attr(""),
      category_slug: this.attr(""),
      product: this.attr(""),
      product_slug: this.attr(""),
      version_slug: this.attr(""),
      description: this.attr(""),
      duration: this.attr(""),
      price_cents: this.attr(null),
    };
  }

  static async fetchAll() {
    let test = await this.axios.get(`/products`);
    Requests.markAsLoaded('products')
    if(test.data)
      test.data.forEach(product => {
        this.insert({ data: product });
      }); 
  }

}

