import createNumberMask from "text-mask-addons/dist/createNumberMask";

export const ALERT = "alert";
export const AUTH = "auth";
export const TEXT_SECTION = "text_section";
export const TEXT_SECTION_LONG = "text_section_long";
export const TEXT_SECTION_XS = "text_section_xs";
export const SUB_HEADLINE = "sub_headline";
export const NAME_REVIEW = "name_review";

export const DISPLAY_ONLY_FIELDS = [
  ALERT,
  AUTH,
  TEXT_SECTION,
  TEXT_SECTION_XS,
  SUB_HEADLINE,
  TEXT_SECTION_LONG,
  NAME_REVIEW,
];

export const TEXT_INPUT = "text";
export const TEXT_INPUT_LONG = "text_long";
export const TEXT_INPUT_MASK = "text-input-mask";
export const NUMBER_FIELD = "number_long";
export const NUMBER = "number";
export const PERCENTAGE = "percentage";
export const TEXTAREA = "textarea";
export const TEXTAREA_LONG = "textarea_long";
export const YES_NO = "yes_no";
export const RADIO = "radio";
export const RADIO_LONG = "radio_long";
export const RADIO_ALT = "radio_alt";
export const ADDON_RADIO = "addon_radio";
export const CHECKBOX = "checkbox";
export const CHECKBOX_LONG = "checkbox_long";
export const CHOOSE_COMPANY = "choose_company";
export const MULTI_CHECK = "multi_check_long_alt";
export const PHONE = "phone_number";
export const SOCIAL_SECURITY_NUMBER = "social_security_number";
export const SOCIAL_SECURITY_NUMBER_LONG = "social_security_number_long";
export const TEXT_MULTIPLIER = "multiplier";
export const TEXT_MULTIPLIER_ALT = "multiplier_alt"; // set up for v1 forms
export const TEXT_MULTIPLIER_LONG_ALT = "multiplier_long_alt"; // set up for v1 forms
export const TEXT_MULTIPLIER_LONG = "multiplier_long";
export const TEXTAREA_MULTIPLIER = "multiplier_area_long";
export const NESTED_MULTIPLIER = "nested_multiplier";
export const NESTED = "nested_fields";
export const DATE_CALCULATOR = "date_calculator";
export const DATE_PICKER = "date-picker";
export const CHECKBOX_GROUP = "checkbox_group";
export const MULTI_CHECKBOX_ALT = "multi_checkbox_alt"; // set up for v1 forms
export const CHECKBOX_NESTED = "checkbox_nested";
export const RADIO_WITH_OPTIONS = "radio_with_options";
export const SELECT_FIELDS = ["state_select", "select"];
export const SELECT_MULTIPLIER_WITH_PRICING = "select_multiplier_with_pricing";
export const COUNTRY_MULTI_SELECT = "country_multi_select";
export const BF_PACKAGES = "bf_packages";
export const CLEAR = "clear"; // set up for v1 forms, doesn't do anything
export const RADIO_INLINE_ALT = "radio_inline_alt"; // set up for v1 forms
export const RADIO_INLINE = "radio_inline"; // set up for v1 forms
export const MULTIPLIER_LEFT = "multiplier_left"; // set up for v1 forms
export const RADIO_ALT_LONG = "radio_alt_long"; // set up for v1 forms
export const UPLOAD = "upload"; 
export const EIN = "ein"; 
export const FINCEN_ID = "fincen_id"; 




const INPUT_MASKS = {
  [SOCIAL_SECURITY_NUMBER]: "###-##-####",
  [SOCIAL_SECURITY_NUMBER_LONG]: "###-##-####",
  [NUMBER_FIELD]: "##################################",
  [NUMBER]: "##################################",
  [EIN]: "##-#######",
  [FINCEN_ID]: "####-####-####",

};

const VALIDATION_REGEXES = {
  [PHONE]: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  [SOCIAL_SECURITY_NUMBER]: /^\d{3}-\d{2}-\d{4}/,
  [SOCIAL_SECURITY_NUMBER_LONG]: /^\d{3}-\d{2}-\d{4}/,
  [NUMBER_FIELD]: /^\d+/,
  [NUMBER]: /^\d+/,
  [PERCENTAGE]: /^\d+/,
  [EIN]: /^\d{2}-\d{7}/,
  [FINCEN_ID]: /^\d{4}-\d{4}-\d{4}/,
};

export default class FormField {
  constructor(object) {
    this.field_type = object.field_type; // type of field in form
    this.name = object.name; // name of field in submission
    this.pattern = object.pattern; // pattern for fields that need patterns
    this.meta = object.meta;
    this.label = object.label;
    this.options = object.options;
    this.default = object.default;
    this.helper_text = object.helper_text;
    this.validation = object.validation;
    this.display_rules = object.display_rules;
    this.review_width = object.review_width;
    this.width = object.width;
    this.placeholder = object.placeholder;
    this.value = object.value;
    this.disabled = object.disabled;
    this.destructive_field = object.destructive_field;
    this.text = object.text;
    if (object.fields) {
      let newFields = object.fields.map((field) => new this.constructor(field));
      this.fields = newFields;
    }
    this.button_text = object.button_text;
    this.alsoAs = object.alsoAs;
    this.clone_to_also_as = object.clone_to_also_as;
    this.is_new_datepicker = object.is_new_datepicker;
    this.is_bordered = object.is_bordered;
    this.is_ssn = object.is_ssn;
    this.raw_value = object.raw_value; // needed for currency to send raw number
    this.price = object.price;
    this.unit = object.unit;
    this.illustration = object.illustration;
    this.update_company = object.update_company;
  }

  get isSelect() {
    return (
      SELECT_FIELDS.includes(this.field_type) ||
      SELECT_FIELDS.includes(this.field_type.replace("_long", ""))
    );
  }

  get required() {
    return this.validation && this.validation.required;
  }

  get isDisplayOnly() {
    return DISPLAY_ONLY_FIELDS.includes(this.field_type);
  }

  get isAlert() {
    return this.field_type == ALERT;
  }

  get isAuth() {
    return this.field_type == AUTH;
  }
  get isPackages() {
    return this.field_type == BF_PACKAGES;
  }
  get isTextSection() {
    return (
      this.field_type == TEXT_SECTION || this.field_type == TEXT_SECTION_LONG
    );
  }

  get isTextInput() {
    return [
      TEXT_INPUT,
      PHONE,
      SOCIAL_SECURITY_NUMBER_LONG,
      SOCIAL_SECURITY_NUMBER,
      TEXT_INPUT_LONG,
      TEXT_INPUT_MASK,
      NUMBER_FIELD,
      NUMBER,
      EIN,
      FINCEN_ID
    ].includes(this.field_type);
  }

  get isYesNo() {
    return this.field_type == YES_NO;
  }

  get isRadio() {
    return this.field_type == RADIO || this.field_type == RADIO_ALT || this.field_type == RADIO_LONG || this.field_type == RADIO_INLINE_ALT || this.field_type == RADIO_INLINE || this.field_type == RADIO_ALT_LONG;
  }

  get isAddonRadio() {
    return this.field_type == ADDON_RADIO;
  }

  get isCheckbox() {
    return this.field_type == CHECKBOX || this.field_type == CHECKBOX_LONG || this.field_type == MULTI_CHECK;
  }

  get isSubHeadline() {
    return this.field_type == SUB_HEADLINE;
  }
  get isTextXS() {
    return this.field_type == TEXT_SECTION_XS;
  }

  get isFieldMultiplier() {
    return [
      TEXT_MULTIPLIER,
      TEXT_MULTIPLIER_ALT,
      TEXT_MULTIPLIER_LONG_ALT,
      TEXTAREA_MULTIPLIER,
      TEXT_MULTIPLIER_LONG,
      SELECT_MULTIPLIER_WITH_PRICING,
      MULTIPLIER_LEFT,
    ].includes(this.field_type);
  }


  get isTextareaMultiplier() {
    return this.field_type == TEXTAREA_MULTIPLIER;
  }

  get isSelectMultiplier() {
    return this.field_type == SELECT_MULTIPLIER_WITH_PRICING;
  }

  get isNestedMultiplier() {
    return this.field_type == NESTED_MULTIPLIER;
  }

  get isNested() {
    return this.field_type == NESTED;
  }

  get isDateCalculator() {
    return this.field_type == DATE_CALCULATOR;
  }

  get hasPricingOption() {
    return this.field_type == SELECT_MULTIPLIER_WITH_PRICING;
  }

  get isTextArea() {
    return [TEXTAREA_LONG, TEXTAREA].includes(this.field_type);
  }

  get isNameReview() {
    return this.field_type == NAME_REVIEW;
  }

  get isDatepicker() {
    return this.field_type == DATE_PICKER;
  }

  get isCheckboxGroup() {
    return this.field_type == CHECKBOX_GROUP || this.field_type === MULTI_CHECKBOX_ALT;
  }

  get isCheckboxNested() {
    return this.field_type == CHECKBOX_NESTED;
  }

  get isRadioWithOptions() {
    return this.field_type == RADIO_WITH_OPTIONS;
  }
  get isPercentage() {
    return this.field_type == PERCENTAGE;
  }

  get hasLabel() {
    return !this.isCheckboxGroup;
  }

  get isWrittenDate() {
    return this.meta && this.meta.format == "MMMM d, yyyy";
  }

  get isCountryMultiselect() {
    return this.field_type == COUNTRY_MULTI_SELECT;
  }

  get isCompanySelect() {
    return this.field_type == CHOOSE_COMPANY;
  }

  get isBFPackages() {
    return this.field_type == BF_PACKAGES;
  }

  get isUpload() {
    return this.field_type == UPLOAD;
  }


  get dateFormatOptions() {
    if (this.isWrittenDate) {
      return { year: "numeric", month: "long", day: "numeric" };
    } else {
      return { year: "numeric", month: "numeric", day: "numeric" };
    }
  }

  get dateMaskOptions() {
    if (this.meta && this.meta.format == "MMMM d, yyyy") {
      return { year: "numeric", month: "long", day: "numeric" };
    } else {
      return { year: "numeric", month: "numeric", day: "numeric" };
    }
  }

  get mask() {
    if (this.pattern) {
      return createNumberMask({
        prefix: this.meta.currency,
        allowDecimal: true,
        decimalLimit: 6,
        includeThousandsSeparator: true
      });
    } else {
      return INPUT_MASKS[this.field_type];
    }
  }

  get rules() {
    let rules = {};
    if (this.required) rules.required = true;
    if (VALIDATION_REGEXES[this.field_type])
      rules.regex = VALIDATION_REGEXES[this.field_type];
    if (this.meta && this.meta.autocomplete == "email") rules.email = true;
    if (this.meta && this.meta.autocomplete == "password") rules.min = "8";
    if (this.validation && this.validation.digits)
      rules.digits = this.validation.digits;
    return rules;
  }

  get inputType() {
    if (this.meta) {
      if (["password", "email"].includes(this.meta.autocomplete)) {
        return this.meta.autocomplete;
      } else if (this.meta.autocomplete == "phone") {
        return "tel";
      } else {
        return "text";
      }
    } else if (this.field_type == NUMBER_FIELD || this.field_type == NUMBER) {
      return "number";
    } else if (this.field_type == PHONE) {
      return "tel";
    } else {
      ("text");
    }
  }

  get autocomplete() {
    return (this.meta && this.meta.autocomplete) || "on";
  }
}
