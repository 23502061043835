import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";
export default class UserCards extends BaseModel {
  static entity = "UserCards";

  static fields() {
    return {
      id: this.attr(null),
      first_name: this.attr(""),
      last_name: this.attr(""),
      postal_code: this.attr(""),
      card_type: this.attr(""),
      last_four: this.attr(""),
      exp_month: this.attr(null),
      exp_year: this.attr(null),
      primary_payment_method: this.attr(null),
      object: this.attr(""),
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/billing_infos`);
    Requests.markAsLoaded('UserCards')
      this.create({ data: data });
  }

  static async deleteCard(id) {
    let { data } = await this.axios.delete(`/billing_infos/${id}`);
    await this.fetchAll();
  }

  static async makeDefault(id) {
    let { data } = await this.axios.patch(`/billing_infos/${id}`, {
      primary_payment_method: true
    });
    this.fetchAll();
  }
  
}
