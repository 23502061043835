

export const getDomain = function () {
  if (!window.location.origin) { // Some browsers (mainly IE) does not have this property, so we need to build it manually...
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '')
  }
  return window.location.origin
}

/**
 * Get Node env
 */
export const getEnv = function () {
  return process.env.NODE_ENV
}

export const getVersion = function () {
  return process.env.VERSION_LABEL
}

export const isMock = () => process.env.NODE_ENV === 'development' && process.env.IS_MOCK === 'true'

export const isApiCompleted = () => process.env.NODE_ENV === 'development' && process.env.IS_API_COMPLETED === 'true'

export const devWarning = str => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(`[DEV-WARN]: ${str}`)
  }
}

export const devError = str => {
  if (process.env.NODE_ENV === 'development') {
    console.error(`[DEV-ERROR]: ${str}`)
  }
}

export const loadFsIdentity = data => {
  if (process.env.NODE_ENV !== 'development') {
    window.FS.identify(data.id, {
      displayName: `${data.firstName} ${data.lastName}`,
      email: `${data.email}`,
      'checkoutTest_str': data.checkoutTest,
      'formTest_str': data.formTest,
      'locale_str': `${data.localeId}`
    })
  }
}

export const fsEvent = (eventName, eventData) => {
  if (process.env.NODE_ENV !== 'development') {
    try {
      window.FS.event(eventName, eventData)
    } catch(e) {
      console.error(e)
    }
  } else {
    console.log('fsEvent')
    console.log('eventName:', eventName)
    console.log('eventData:', eventData)
  }
}
