import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";
export default class UserCompanies extends BaseModel {
  static entity = "UserCompanies";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      entity_type: this.attr(""),
      formation_date: this.attr(""),
      started_at: this.attr(null),
      logo_url: this.attr(""),
      state_formation: this.attr(""),
      compliancesEnabled: this.attr(false),
      has_formation: this.attr(false),
      is_registered_agent: this.attr(false),
      status: this.attr(null),
      foreign_entity_of: this.attr(null),
      registered_agent_active:this.attr(null),
      compliance_guard_active:this.attr(null),
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/companies`);
    Requests.markAsLoaded('UserCompanies')
    data.companies.forEach(company => {
      this.insert({ data: company });
    });
    
  }

  static async createRandomCompany(state, completed, registered_agent, include_files) {
    let { data } = await this.axios.get(`/creator/company.json`, {
      params: {
        ...(state ? { state: state } : {}),
        ...(completed ? { completed: completed } : {}),
        ...(registered_agent ? { registered_agent: registered_agent } : {}),
        ...(include_files ? { include_files: include_files } : {})
      }
      });
    this.insert({ data });
  }

  static async addAddonsToCompany(company_id, addon) {
    let { data } = await this.axios.get(`/creator/company_addon.json`, {
      params: {
        ...(company_id ? { company_id: company_id } : {}),
        ...(addon ? { addon: addon } : {}),
      }
      });
    this.insert({ data });
  }

  static async addFilesToCompany(company_id) {
    let { data } = await this.axios.get(`/creator/company_file.json`, {
      params: {
        ...(company_id ? { company_id: company_id } : {}),
      }
      });
    this.insert({ data });
  }

    // by default findBy ID.
    static findByUrl({ id }) {
      return `/companies/${id}`;
    }
  
    static async findBy(params) {
      let {data} = await this.axios.get(this.findByUrl(params));
      this.insert({ data });
    }


}
