import client from './client.js'	

class Post {	
  static async list (options = {}) {	
    let butterResponse = await client.post.list(options)	
    //return butterResponse	
    return {	
      posts: butterResponse.data.data,	
      nextPage: butterResponse.data.meta.next_page,	
      previousPage: butterResponse.data.meta.previous_page,	
      postCount: butterResponse.data.meta.count	
    }	
  }	
}	

export default Post