import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class ForeignEntity extends BaseModel {
  static entity = "ForeignEntity";

  static fields() {
    return {
      name: this.attr(null),
      formation_date: this.attr(null),
      state_formation: this.attr(""),
      formation_date: this.attr(null),
      registered_agent_active: this.attr(false),
      compliance_guard_active: this.attr(false),
    };
  }

  static async fetchFEs(id) {
    let { data } = await this.axios.get(`/companies/${id}/foreign_entities`);
    Requests.markAsLoaded('ForeignEntities')
    data.companies.forEach(company => {
      this.insert({ data: company });
    });
  }

  static async updateFormationDate(feID, date, companyID) {
     return this.axios
      .patch(`/companies/${feID}`, date)
      .then(({ data }) => {
        Requests.markAsNotLoaded('ForeignEntities')
        this.fetchFEs(companyID)
      });
 }
}
