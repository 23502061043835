import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";

export default class FormReceiptItem extends BaseModel {
  static entity = "form_receipt_item";

  static fields() {
    return {
      id: this.attr(null),
      form_id: this.attr(null),
      item_type: this.attr(null),
      cost: this.attr(0),
      name: this.attr(null),
      display_rules: this.attr(null),
      tooltip: this.attr(null),
      free_type: this.attr(null),
      cost_type: this.attr(null),
      is_visible: this.attr(false),
      editable: this.attr(false),
      field_name: this.attr(null),
      editable_value: this.attr(null),
      interval: this.attr(null),
    };
  }
}
