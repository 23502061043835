import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class UserDocuments extends BaseModel {
  static entity = "userDocuments";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      started_at: this.attr(null),
      completed_at: this.attr(false),
      company_upload:this.attr(false),
      word_path: this.attr(null),
      pdf_path: this.attr(1),
      document_type: this.attr(null),
      purchased: this.attr(null),
      state: this.attr(""),
      product_id: this.attr(null),
      pdf_only: this.attr(null),
      completed_steps: this.attr(null),
      max_steps: this.attr(null),
      company_id: this.attr(null),
      company: this.attr(""),
      status: this.attr(""),
      category: this.attr(""),
      preview:this.attr(null),
      is_v2: this.attr(false),
      version_slug: this.attr(""),
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/documents`);
    Requests.markAsLoaded('userDocuments')
    this.create({ data });
  }

    // by default findBy ID.
    static findByUrl({ id }) {
  
      return `/documents/${id}`;
    }
  
    static async findBy(params) {
      let response = await this.axios.get(this.findByUrl(params));
      return response.data;
    }

//product_id=power-of-attorney&state=ca&purchased=true
static async createDocument(product_id, state, purchased) {
  let { data } = await this.axios.get(`/creator/document.json/`, {
    params: {
      ...(product_id ? { product_id: product_id } : {}),
      ...(state ? { state: state } : {}),
      ...(purchased ? { purchased: purchased } : {})
    }
    });
  this.insert({ data });

  this.fetchAll();
}
static async deleteDocument(product_id) {
  let { data } = await this.axios.delete(`/document/delete/${product_id}`);
  this.fetchAll();
}

    static async getPreview(id) {
      let response = await this.axios.get(`/documents/${id}/preview`);
      Requests.markAsLoaded('docPreview' + id)
      this.update({ where: id, data(userDoc) { userDoc.preview = response.data}})

    }


}
