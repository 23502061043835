import BaseModel from "./BaseModel";
import FormPage from "./FormPage";
import FormReceiptItem from "./FormReceiptItem";
import FormSection from "./FormSection";
import { displayRuleHelper } from "display-rules";

export default class OrderForm extends BaseModel {
  static entity = "order_forms_v3";

  static findByUrl({ version_slug, orderId, order_type }) {
    return `/${
      this.entity
    }/${version_slug}?order_id=${orderId}&nuxt=true&order_type=${order_type}`;
  }

  static createFormUrl({ type }) {
    return `/${this.entity}/creation_form/${type}`;
  }

  static fields() {
    return {
      state: this.attr(null),
      id: this.attr(null),
      type: this.attr(null),
      name: this.attr(null),
      current_page_id: this.attr(null),
      create_button: this.attr(""),
      version_slug: this.attr(null),
      feature_flags: this.attr(null),
      show_receipt_box: this.attr(false),
      review_disabled: this.attr(false),
      receipt_items: this.hasMany(FormReceiptItem, "form_id"),
      pages: this.hasMany(FormPage, "form_id"),
      sections: this.hasMany(FormSection, "form_id"),
      is_loading: this.attr(true),
    };
  }

  static async loadOrUpdateActive(params) {
    let data = await this.findBy(params);
    await this.insertOrUpdate({
      data: { ...data, is_loading: false },
    });
    let newForm = this.query()
      .withAllRecursive()
      .first();
    // remove pages that are lingering after destructiveFields change
    newForm.cleanOldRelations(data);
    return newForm;
  }

  async cleanOldRelations(data) {
    let receiptItemIds = data.receipt_items.map((item) => item.id);
    let pageIds = data.pages.map((item) => item.id);
    let sectionIds = data.sections.map((item) => item.id);
    this.receipt_items.forEach((item) => {
      if (!receiptItemIds.includes(item.id)) FormReceiptItem.delete(item.id);
    });
    this.pages.forEach((item) => {
      if (!pageIds.includes(item.id)) FormPage.delete(item.id);
    });
    this.sections.forEach((item) => {
      if (!sectionIds.includes(item.id)) FormSection.delete(item.id);
    });
  }

  setCurrentPage(pageId) {
    this.$update({ current_page_id: pageId });
  }

  get visiblePages() {
    return FormPage.query()
      .where("form_id", this.id)
      .where("is_visible", true)
      .orderBy("ordered")
      .get();
  }

  get visibleReceiptItems() {
    return FormReceiptItem.query()
      .where("form_id", this.id)
      .where("is_visible", true)
      .orderBy("ordered")
      .get();
  }

  get orderedSections() {
    return FormSection.query()
      .where("form_id", this.id)
      .orderBy("ordered")
      .get();
  }

  async setVisibleForData(data, isComplete) {
    return Promise.all([
      this.setVisiblePages(data, isComplete),
      this.setVisibleReceiptItems(data),
    ]);
  }

  shouldHidePageOnEdit(id){
    let pagesToBeHidden = ['state', 'filing', 'poa-type', 'document-length', 'governing_state', 'company']
    return(pagesToBeHidden.includes(id))
  }

  async setVisiblePages(newData, isComplete) {
    // return all pages
    let pages = FormPage.query()
      .where("form_id", this.id)
      .orderBy("ordered")
      .get();
    // build update payload
    let data = pages.reduce((saveData, item) => {
      // skip updating pages that already have the correct is_visible.
      var is_visible = displayRuleHelper(item.display_rules, newData);
      if ((is_visible != item.is_visible)) {
        let visibleComplete = true
        if(isComplete)
           visibleComplete = !(item.hide_on_edit || this.shouldHidePageOnEdit(item.id))
        var hide = false
        if(visibleComplete === false)
          return saveData.concat({
            id: item.id,
            hide,
          });
        else 
        return saveData.concat({
          id: item.id,
          is_visible,
        });
      } 
        return saveData;
      
    }, []);
    // batch update is_visible for all pages
    return FormPage.insertOrUpdate({ data });
  }

  async setVisibleReceiptItems(newData) {
    // return all receipt items for current form
    let items = FormReceiptItem.query()
      .where("form_id", this.id)
      .orderBy("ordered")
      .get();
    // build update payload
    let data = items.reduce((saveData, item) => {
      // skip updating receipt_items that already have the correct is_visible.
      let is_visible = displayRuleHelper(item.display_rules, newData);
      if (is_visible != item.is_visible) {
        return saveData.concat({
          id: item.id,
          is_visible,
        });
      } else {
        return saveData;
      }
    }, []);
    // batch update is_visible for all receipt items
    return FormReceiptItem.insertOrUpdate({ data });
  }

  async setSectionAttrs(object) {
    let sections = FormSection.query()
      .where("form_id", this.id)
      .orderBy("ordered")
      .get();

    let data = sections.reduce((saveData, section) => {
      return saveData.concat({
        id: section.id,
        ...section.cacheData(object),
      });
    }, []);
    // batch update is_visible for all receipt items
    return FormSection.insertOrUpdate({ data });
  }
}
