import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import FormField from "./FormField";

export default class FormPage extends BaseModel {
  static entity = "form_page";

  static fields() {
    return {
      id: this.attr(null),
      form_id: this.attr(null),
      name: this.attr(null),
      display_rules: this.attr(null),
      has_required_fields: this.attr(null),
      fields: this.attr([], (fieldsVal) => {
        if (!fieldsVal) return [];
        return fieldsVal.map((field) => new FormField(field));
      }),
      current: this.attr(false),
      description: this.attr(null),
      faqs: this.attr(null),
      page_type: this.attr(null),
      review_fields: this.attr([]),
      ordered: this.attr(null),
      section_id: this.attr(null),
      illustration: this.attr(null),
      is_visible: this.attr(false),
      hide_on_edit: this.attr(false),
    };
  }

  get destructiveFields() {
    return this.fields.filter((f) => f.destructive_field);
  }

  get hasDestructiveFields() {
    return this.destructiveFields.length > 0;
  }
}
