import API from '../api/api'

export const state = () => ({
  data: {},
  loading: true,
  error: null
})

export const mutations = {
  set (state, data) {
    state.data = data
    state.loading = false
    state.error = data.error
  }
}

export const actions = {
  fetch ({ commit }, slug) {
    return Promise.all([
      API.categories.fetch(this.$axios, slug),
      API.butterPages.get(this.$axios, slug, {})
    ]).then(function(results) {
      let [ apiResult, butterResult ] = results
      let data = {
        ...apiResult,
        ...butterResult.fields
      }
      commit("set", data)
    }).catch(function(error) {
      commit("set", { error: error.message })
      throw error
    })
  }
}