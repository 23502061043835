import API from '../api/api'

export const state = () => ({
  data: [],
  loading: true,
  error: null
})

export const mutations = {
  set (state, data) {
    state.data = data.data
    state.loading = data.loading
    state.error = data.error
  }
}

export const actions = {
  async getAddons ({ commit }) {
    try {
      const data = await API.addons.getAddons(this.$axios)
      commit("set", {
        data,
        loading: false,
        error: false
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}