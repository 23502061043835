function get(client, slug, params = {}) {
  let url 
  if(params.type !== 'custom_lander')
  url = `/butter_pages/${slug}?type=${params.type}`
  else 
  url =`/butter_pages/${params.unique_id}?type=${params.type}`
  return client
    .$get(url, { timeout: 15000 })
    .then((response) => response)
    .catch((error) => {
        throw error
    });
  
}

export default {
  get,
};
