export const states = [
  {value: 1, label: "Alabama", abbreviation: "AL"},
  {value: 2, label: "Alaska", abbreviation: "AK"},
  {value: 3, label: "Arizona",  abbreviation: "AZ"},
  {value: 4, label: "Arkansas", abbreviation: "AR"},
  {value: 5, label: "California", abbreviation: "CA"},
  {value: 6, label: "Colorado", abbreviation: "CO"},
  {value: 7, label: "Connecticut", abbreviation: "CT"},
  {value: 8, label: "Delaware", abbreviation: "DE"},
  {value: 52, label: "District of Columbia",   abbreviation: "DC"},
  {value: 9, label: "Florida",  abbreviation: "FL"},
  {value: 10, label: "Georgia", abbreviation: "GA"},
  {value: 11, label: "Hawaii", abbreviation: "HI"},
  {value: 12, label: "Idaho", abbreviation: "ID"},
  {value: 13, label: "Illinois", abbreviation: "IL"},
  {value: 14, label: "Indiana", abbreviation: "IN"},
  {value: 15, label: "Iowa",  abbreviation: "IA"},
  {value: 16, label: "Kansas", abbreviation: "KS"},
  {value: 17, label: "Kentucky", abbreviation: "KY"},
  {value: 18, label: "Louisiana", abbreviation: "LA"},
  {value: 19, label: "Maine", abbreviation: "ME"},
  {value: 20, label: "Maryland", abbreviation: "MD"},
  {value: 21, label: "Massachusetts", abbreviation: "MA"},
  {value: 22, label: "Michigan", abbreviation: "MI"},
  {value: 23, label: "Minnesota",  abbreviation: "MN"},
  {value: 24, label: "Mississippi", abbreviation: "MS"},
  {value: 25, label: "Missouri", abbreviation: "MO"},
  {value: 26, label: "Montana", abbreviation: "MT"},
  {value: 27, label: "Nebraska",  abbreviation: "NE"},
  {value: 28, label: "Nevada", abbreviation: "NV"},
  {value: 29, label: "New Hampshire", abbreviation: "NH"},
  {value: 30, label: "New Jersey", abbreviation: "NJ"},
  {value: 31, label: "New Mexico",  abbreviation: "NM"},
  {value: 32, label: "New York", abbreviation: "NY"},
  {value: 33, label: "North Carolina",   abbreviation: "NC"},
  {value: 34, label: "North Dakota", abbreviation: "ND"},
  {value: 35, label: "Ohio", abbreviation: "OH"},
  {value: 36, label: "Oklahoma",  abbreviation: "OK"},
  {value: 37, label: "Oregon", abbreviation: "OR"},
  {value: 38, label: "Pennsylvania", abbreviation: "PA"},
  {value: 39, label: "Rhode Island",  abbreviation: "RI"},
  {value: 40, label: "South Carolina", abbreviation: "SC"},
  {value: 41, label: "South Dakota", abbreviation: "SD"},
  {value: 42, label: "Tennessee",  abbreviation: "TN"},
  {value: 43, label: "Texas", abbreviation: "TX"},
  {value: 44, label: "Utah", abbreviation: "UT"},
  {value: 45, label: "Vermont",   abbreviation: "VT"},
  {value: 46, label: "Virginia", abbreviation: "VA"},
  {value: 47, label: "Washington", abbreviation: "WA"},
  {value: 48, label: "West Virginia", abbreviation: "WV"},
  {value: 49, label: "Wisconsin", abbreviation: "WI"},
  {value: 50, label: "Wyoming", abbreviation: "WY"}
]

export const statesAlt = [
  { label: "Alabama", value: "AL"},
  { label: "Alaska", value: "AK"},
  { label: "Arizona",  value: "AZ"},
  {label: "Arkansas", value: "AR"},
  { label: "California", value: "CA"},
  { label: "Colorado", value: "CO"},
  {label: "Connecticut", value: "CT"},
  { label: "Delaware", value: "DE"},
  { label: "District of Columbia",   value: "DC"},
  {label: "Florida",  value: "FL"},
  { label: "Georgia", value: "GA"},
  { label: "Hawaii", value: "HI"},
  {label: "Idaho", value: "ID"},
  { label: "Illinois", value: "IL"},
  { label: "Indiana", value: "IN"},
  { label: "Iowa",  value: "IA"},
  { label: "Kansas", value: "KS"},
  { label: "Kentucky", value: "KY"},
  { label: "Louisiana", value: "LA"},
  { label: "Maine", value: "ME"},
  {label: "Maryland", value: "MD"},
  {label: "Massachusetts", value: "MA"},
  { label: "Michigan", value: "MI"},
  { label: "Minnesota",  value: "MN"},
  { label: "Mississippi", value: "MS"},
  { label: "Missouri", value: "MO"},
  { label: "Montana", value: "MT"},
  { label: "Nebraska",  value: "NE"},
  { label: "Nevada", value: "NV"},
  { label: "New Hampshire", value: "NH"},
  { label: "New Jersey", value: "NJ"},
  { label: "New Mexico",  value: "NM"},
{label: "New York", value: "NY"},
  { label: "North Carolina",   value: "NC"},
  {label: "North Dakota", value: "ND"},
  { label: "Ohio", value: "OH"},
  { label: "Oklahoma",  value: "OK"},
  { label: "Oregon", value: "OR"},
  {label: "Pennsylvania", value: "PA"},
  { label: "Rhode Island",  value: "RI"},
  { label: "South Carolina", value: "SC"},
  {label: "South Dakota", value: "SD"},
{label: "Tennessee",  value: "TN"},
  {label: "Texas", value: "TX"},
  {label: "Utah", value: "UT"},
  {label: "Vermont",   value: "VT"},
  {label: "Virginia", value: "VA"},
  {label: "Washington", value: "WA"},
  { label: "West Virginia", value: "WV"},
  { label: "Wisconsin", value: "WI"},
  {label: "Wyoming", value: "WY"}
]

export const stateNameByValue = (stateId) => {
  if (!stateId) return null
  let state = states.find((state) => {
    return state.value.toString() === stateId.toString()
  })
  if (state) return state.label
}

export const stateNameByAbbreviation = (abbreviation) => {
  if (!abbreviation) return null
  let state = states.find((state) => {
    return state.abbreviation === abbreviation
  })
  if (state) return state.label
}

export const stateValueByAbbreviation = (abbreviation) => {
  if (!abbreviation) return null
  let state = states.find((state) => {
    return state.abbreviation === abbreviation
  })
  if (state) return state.value
}

export const stateAbbreviationByValue = (value) => {
  if (!value) return null
  let state = states.find((state) => {
    return state.value === value
  })
  if (state) return state.abbreviation
}

export const stateAbbreviationByName = (name) => {
  if (!name) return null
  let state = states.find((state) => {
    return state.label === name
  })
  if (state) return state.abbreviation
}

// export const getStatesWithAbbreviationAsValue = () => {
//   return [
//     states.map((state) => {
//       return {
//         label: state.label,
//         value: state.abbreviation,
//       };
//     }),
//   ];
// }