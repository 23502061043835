async function getAddons(client) {
  try {
    const url = `/addons`

    const response = await client.$get(url)
    return response
  } catch (error) {
    throw error
  }
}

export default {
  getAddons: getAddons
}