import { Database } from "@vuex-orm/core";
import Order from "@/models/Order";
import OrderForm from "@/models/OrderForm";
import FormPage from "@/models/FormPage";
import FormReceiptItem from "@/models/FormReceiptItem";
import User from "@/models/User";
import FormSection from "@/models/FormSection";
import BankOfAmericaLead from "@/models/BankOfAmericaLead";
import Document from "@/models/Document";
import Plan from "@/models/Plan";
import Product from "@/models/Product";
import Contact from "@/models/Contact";
import OrderMeta from "@/models/OrderMeta";
import UserDocuments from "@/models/UserDocuments";
import UserCompanies from "@/models/UserCompanies";
import Company from "@/models/Company";
import ForeignEntity from "@/models/ForeignEntity";
import CompanyCorrespondence from "@/models/CompanyCorrespondence";
import Addons from "@/models/Addons";
import UserOrders from "@/models/UserOrders";
import UserSavedOrders from "@/models/UserSavedOrders";
import UserOrdersProcessing from "@/models/UserOrdersInProcessing";
import CompanyAddons from "@/models/CompanyAddons";
import Documentation from "@/models/Documentation";
import SuggestedDocuments from "@/models/SuggestedDocuments";
import BestowQuote from "@/models/BestowQuote";
import RecommendedDocs from "@/models/RecommendedDocs";
import Requests from "@/models/Requests";
import UserSubscriptions from "@/models/UserSubscriptions";
import UserCards from "@/models/UserCards";


const database = new Database();

database.register(Order);
database.register(OrderForm);
database.register(FormPage);
database.register(OrderMeta);
database.register(FormReceiptItem);
database.register(User);
database.register(FormSection);
database.register(BankOfAmericaLead);
database.register(Document);
database.register(Plan);
database.register(Product);
database.register(Contact);
database.register(UserDocuments);
database.register(UserOrders);
database.register(UserSavedOrders);
database.register(UserOrdersProcessing);
database.register(UserCompanies);
database.register(Company);
database.register(ForeignEntity);
database.register(CompanyCorrespondence);
database.register(CompanyAddons);
database.register(Addons);
database.register(Documentation);
database.register(SuggestedDocuments);
database.register(BestowQuote);
database.register(RecommendedDocs);
database.register(Requests);
database.register(UserSubscriptions);
database.register(UserCards);

export default database;
