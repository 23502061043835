async function get(client, slug, params = {}) {
  let url = `/butter_categories/${slug}?page=${params.page}&tag_slug=${params.tag_slug}`
  return client.$get(url,
    { timeout: 2000 }
  )
    .then(response => response)
    .catch(error => {
      throw error
    })
}

export default {
  get,
}
