import BaseOrder from "./BaseOrder";
import OrderForm from "./OrderForm";
import { stateNameByValue } from "@/src/states";
import { cloneDeep } from "lodash";
import { fsEvent } from "~/services/util";

export default class Order extends BaseOrder {
  static entity = "order_meta";
  static primaryKey = "key";

  static fields() {
    return {
      key: this.attr(null),
      value: this.attr(""),
    };
  }
}
