import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class RecommendedDocs extends BaseModel {
  static entity = "RecommendedDocs";

  static fields() {
    return {
      id: this.attr(null),
      category: this.attr(""),
      category_slug: this.attr(""),
      product: this.attr(""),
      product_slug: this.attr(""),
      version_slug: this.attr(""),
      recommended_doc: this.attr({}),
    };
  }



  static async fetchAll() {
    let { data } = await this.axios.get(`/companies`);
    Requests.markAsLoaded('userRecommendations')
    this.create({ data });

    
  }


}
