import API from '../api/api'

export const state = () => ({
  data: {},
  loading: true,
  error: null
})

export const mutations = {
  set (state, data) {
    state.data = data
    state.loading = false
  }
}

export const actions = {
  async getUser ({ commit }, params) {
    try {
      const data = await API.user.getUser(this.$axios, params)
      commit("set", data)
    } catch (error) {
      // console.log("error.message", error.message)
      commit("set", {})
    }
  }
}