import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class SuggestedDocuments extends BaseModel {
  static entity = "SuggestedDocuments";

  static fields() {
    return {
      name: this.attr(null),
      category: this.attr(null),
      slug: this.attr(null),
      category_slug: this.attr(null),
      description: this.attr(null),
    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/profile/suggested_documents`);
    Requests.markAsLoaded('suggested_documents')
    this.create({ data});
    
    
  }

}
