import BaseModel from "./BaseModel.js";
import OrderForm from "./OrderForm.js";
import { stateNameByValue } from "@/src/states.js";
import { cloneDeep } from "lodash";

export default class BaseOrder extends BaseModel {
  static beforeUpdate(model) {
    let dataCopy = cloneDeep(model.data);
    if (dataCopy.state_id) {
      dataCopy.state = stateNameByValue(dataCopy.state_id);
    } else {
      dataCopy.state = dataCopy.state || stateNameByValue(model.state);
    }
    model.data = dataCopy;
    return true;
  }

  async createWithApi() {
    let data = await this.constructor.axios.post(`${this.constructor.entity}`, {
      data: this.data,
    });
    let newOrder = await this.constructor.create(data);
    return this.constructor.query().first();
  }

  setLoading() {
    this.constructor.update({
      id: this.id,
      is_form_loading: true,
      is_form_error: false,
    });
  }

  setLoaded(form) {
    form.$update({ is_loading: false });
    this.constructor.update({
      id: this.id,
      is_form_loading: false,
      is_form_error: false,
    });
  }

  setLoadError() {
    this.constructor.update({
      id: this.id,
      is_form_loading: false,
      is_form_error: true,
    });
  }

  async loadForm() {
    return OrderForm.loadActive(this.orderFormParams)
      .then((form) => {
        form.setVisibleForData(this.data, this.completed).then(() => {
          form.setSectionAttrs(this).then(() => {
            this.setLoaded(form);
          });
        });
      })
      .catch(() => this.setLoadError());
  }

  async loadChangedForm() {
    this.$update({ is_form_loading: true });
    return OrderForm.loadOrUpdateActive(this.orderFormParams)
      .then((form) => {
        form.setVisibleForData(this.data, this.completed).then(() => {
          form.setSectionAttrs(this).then(() => {
            this.setLoaded(form);
          });
        });
      })
      .catch(() => this.setLoadError());
  }

  get orderFormParams() {
    throw "orderFormParams not defined.";
  }

  get orderForm() {
    return OrderForm.query()
      .whereId(this.version_slug)
      .withAllRecursive()
      .first();
  }

  saveData(newData) {
    const data = {
      ...this.data,
      ...newData,
    };
    return this.$update({ data });
  }

  updatePage(pageId) {
    let newPageSet = [pageId];
    if (
      this.current_page_id &&
      !this.completed_page_set.includes(this.current_page_id)
    ) {
      newPageSet = [...newPageSet, ...[this.current_page_id]];
    }
    let completed_page_set = [...this.completed_page_set, ...newPageSet];

    return this.$update({
      current_page_id: pageId,
      is_changing_page: true,
      completed_page_set,
    }).then((order) => {
      order.apiSave().then((_) => this.delayedPageUpdate());
    });
  }

  async purchase(params) {
    return this.constructor.axios.patch(
      `${this.constructor.findByUrl({ id: this.id })}/recurly_purchase`, params
    );
  }

  apiSave() {
    throw "Api save not defined";
  }

  async delayedPageUpdate() {
    setTimeout(() => this.$update({ is_changing_page: false }), 300);
  }
}
