import Vue from "vue";
import VueGtagPlugin from "vue-gtag";

const gaMock = {
  event(event, type) {
    console.info(
      `Event: ${event} fired with type ${type}, not logging to GA since this app is running in development.`
    );
  },
  set(parameter, value) {
    console.info(
      `Set: ${parameter}=${value}, not logging to GA since this app is running in development.`
    );
  },
};

export default async ({ app, ...ctx }, inject) => {
  if (
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "staging"
  ) {
    Vue.use(VueGtagPlugin, {
      includes: [
        { id: 'AW-1000718781',
        params: { allow_enhanced_conversions: true },
      }
      ],
      config: {
        id: "G-4GW3L3QKZT",
        params: { send_page_view: false },
      }});
  } else {
    ctx.$gtag = gaMock;
    inject("gtag", gaMock);
  }
};
