import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class Documentation extends BaseModel {
  static entity = "Documentation";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(null),
      title: this.attr(null),
      description: this.attr(null),
      isNew: this.attr(null),
      link: this.attr(null),

    };
  }


  static async fetchAll() {
    let { data } = await this.axios.get(`/documentation`);
    Requests.markAsLoaded('Documentation')
    data.forEach(documentation => {
      this.insert({ data: documentation });
    });
    
  }

}
