import { Model } from '@vuex-orm/core'
import BaseModel from './BaseModel'


export default class BestowQuote extends BaseModel {
  static entity = 'bestow_quotes'

  static fields () {
    return {
      id: this.attr(null),
      birth_date: this.attr(null),
      gender: this.attr(null),
      height_feet: this.number(null).nullable(),
      height_inches: this.number(null).nullable(),
      state: this.attr(null),
      weight: this.number(null),
      tobacco: this.boolean(null).nullable(),
      quote: this.attr({}),
      quote_id: this.attr(null)
    }
  }

}