async function getProducts(client) {
  try {
    const url = `/products`;

    const response = await client.$get(url);

    return response;
  } catch (error) {
    throw error;
  }
}

export default {
  getProducts: getProducts,
};
