import BaseModel from "./BaseModel";
import FormPage from "./FormPage";
import { displayRuleHelper } from "display-rules";
import { Liquid } from "liquidjs";
const liquidEngine = new Liquid();

export default class FormSection extends BaseModel {
  static entity = "form_section";

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(null),
      subtitle: this.attr(null),
      pages: this.hasMany(FormPage, "section_id"),
      form_id: this.attr(null),
      completed: this.attr(false),
      inProgress: this.attr(false),
      sectionLiquidTitle: this.attr(""),
      sectionLiquidSubtitle: this.attr(""),
      sectionLiquidSubtitle: this.attr(""),
      progress: this.attr(0),
      ordered: this.attr(null),
    };
  }

  visiblePagesForData(data) {
    return this.visiblePages;
  }

  get visiblePages() {
    return FormPage.query()
      .where("section_id", this.id)
      .where("is_visible", true)
      .orderBy("ordered")
      .get();
  }

  cacheData(object) {
    // return cache data to be batch updated on save/page change
    let visiblePageIds = this.visiblePages.map((p) => p.id);
    let inProgress = visiblePageIds.includes(object.current_page_id);
    return {
      inProgress,
      completed:
        visiblePageIds.length > 0 &&
        visiblePageIds.every((pageId) =>
          object.completed_page_set.includes(pageId)
        ) &&
        !inProgress,
      sectionLiquidTitle: liquidEngine.parseAndRenderSync(
        this.title,
        object.data
      ),
      sectionLiquidSubtitle: this.subtitle  ? liquidEngine.parseAndRenderSync(
        this.subtitle,
        object.data
      ) : "",
      progress: this.calcProgress(object, visiblePageIds),
    };
  }

  calcProgress(object, visiblePageIds) {
    const totalPages = visiblePageIds.length;
    let currentPageIndex = 0;

    visiblePageIds.forEach((pageId, index) => {
      if (pageId == object.current_page_id) currentPageIndex = index;
    });

    return (currentPageIndex / totalPages) * 100;
  }
}
