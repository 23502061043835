async function getStateFees(client) {
  try {
    const url = `/state_info/fees.json`
    const response = client.$get(url)
    return response
  } catch(e) {
    throw e
  }
}

async function getStateTimes(client) {
  try {
    const url = `/state_info/times.json`
    const response = client.$get(url)
    return response
  } catch(e) {
    throw e
  }
}

export default {
  getStateFees, getStateTimes
}